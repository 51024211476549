import React from 'react'
import { graphql, Link } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import { StaticImage } from 'gatsby-plugin-image'

import Layout from 'layouts/index'
import Seo from 'components/seo'
import PosterCard from 'components/card/poster'
import NewsCard from 'components/card/news'
import ZoomGallery from 'components/gallery/zoom'
import I18nImage from 'components/gallery/i18nImage'

import streamFunctions from '/static/data/feature.static'
import newsList from '/static/data/newsList.static'

const MultiPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: 'Multi-CDN for Video Streaming Solutions - Mlytics',
            metaDescription:
              'Deliver your video content with confidence using Mlytics Multi-CDN Management solution. Optimize your video streaming with our reliable & scalable platform',
            metaUrl: 'https://www.mlytics.com/video-stream/multi-cdn-management/',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2022/06/logo_Mlytics_regular.png'
            }
          }}
        />
        <section className="container flex flex-col md:flex-row mb-20 mt-10">
          <div className="flex-1 my-10">
            <div className="px-4 md:pl-20">
              <h1 className="text-primary-400">{t('multiCdnManagement.section1.title')}</h1>
              <p className="py-5 text-gray-500">{t('multiCdnManagement.section1.description')}</p>
              <div className="flex flex-col lg:flex-row gap-3 my-1">
                <Link to="/sign-up" className="btn btn-bold btn-orange inline-block" style={{ width: 208 }}>
                  {t('button.freeTrial.0')}
                </Link>
                <Link
                  to="/lp/get-a-demo/"
                  className="btn btn-bold btn-ghost btn-orange inline-block"
                  style={{ width: 208 }}
                >
                  {t('button.getDemo.0')}
                </Link>
              </div>
            </div>
          </div>
          <div className="flex-1 overflow-hidden">
            <StaticImage
              src="https://static.mlytics.com/images/website/multi_cdn_streaming.png"
              alt={t('multiCdnManagement.section1.imageAlt')}
              style={{ height: 'auto', maxWidth: '600px' }}
            />
          </div>
        </section>
        <section className="p-10">
          <div className="container flex flex-col-reverse md:flex-row">
            <div className="flex flex-1 justify-center">
              <I18nImage
                src="https://static.mlytics.com/images/website/marketplace.png"
                alt={t('multiCdnManagement.section2.imageAlt')}
                style={{ height: 'auto', maxWidth: '100%' }}
              />
            </div>
            <div className="flex-1 mt-10 md:px-8">
              <div>
                <h2 className="text-gray-800">{t('multiCdnManagement.section2.title')}</h2>
                <p className="py-5 text-gray-700">{t('multiCdnManagement.section2.description')}</p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-primary-50 px-10 py-20">
          <div className="container flex flex-col md:flex-row">
            <div className="flex-1 mt-10 md:px-8">
              <div>
                <h2 className="text-gray-800">{t('multiCdnManagement.section3.title')}</h2>
                <p className="py-5 text-gray-700">{t('multiCdnManagement.section3.description')}</p>
              </div>
            </div>
            <div className="flex flex-1 justify-center">
              <ZoomGallery>
                <I18nImage
                  src="https://static.mlytics.com/images/website/multi_cdn_management_bring_cdn.png"
                  alt={t('multiCdnManagement.section3.imageAlt')}
                  style={{ height: 'auto', maxWidth: '100%' }}
                />
              </ZoomGallery>
            </div>
          </div>
        </section>
        <section className="px-10 py-20">
          <div className="container flex flex-col-reverse md:flex-row">
            <div className="flex flex-1 justify-center">
              <I18nImage
                src="https://static.mlytics.com/images/website/video_multi_cdn_management.png"
                alt={t('multiCdnManagement.section4.imageAlt')}
                style={{ height: 'auto', maxWidth: '100%' }}
              />
            </div>
            <div className="flex-1 mt-10 md:px-8">
              <div>
                <h2 className="text-gray-800">{t('multiCdnManagement.section4.title')}</h2>
                <p className="py-5 text-gray-700">{t('multiCdnManagement.section4.description')}</p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-primary-50 px-10 py-20">
          <div className="container flex flex-col md:flex-row">
            <div className="flex-1 mt-10 px-8">
              <div>
                <h2 className="text-gray-800">{t('multiCdnManagement.section5.title')}</h2>
                <p className="text-gray-700">{t('multiCdnManagement.section5.description')}</p>
                <p className="text-gray-700">{t('multiCdnManagement.section5.description2')}</p>
              </div>
            </div>
            <div className="flex flex-1 justify-center">
              <I18nImage
                src="https://static.mlytics.com/images/website/extensive_compatibility.png"
                alt={t('multiCdnManagement.section5.imageAlt')}
                style={{ height: 'auto', maxWidth: '100%' }}
              />
            </div>
          </div>
        </section>
        <section className="px-10 py-20">
          <div className="container flex flex-col-reverse md:flex-row">
            <div className="flex flex-1 justify-center">
              <StaticImage
                src="https://static.mlytics.com/images/website/multi_cdn_strategy.png"
                alt={t('multiCdnManagement.section6.imageAlt')}
                style={{ height: 'auto', maxWidth: '600px' }}
              />
            </div>
            <div className="flex-1 mt-10 px-8">
              <div>
                <h2 className="text-3xl text-gray-800">{t('multiCdnManagement.section6.title')}</h2>
                <p className="py-5 text-gray-700">{t('multiCdnManagement.section6.description')}</p>
              </div>
              <Link to={'/video-stream/instream-switching/'} className="btn btn-green mb-5">
                {t('multiCdnManagement.section6.buttonText')}
              </Link>
            </div>
          </div>
        </section>
        <section className="bg-gradient-blue px-4 md:px-10 py-16">
          <h2 className="text-center text-white text-5xl">{t('heading.learnMoreAboutStream')}</h2>
          <div className="flex flex-col md:flex-row justify-center pt-10">
            {streamFunctions.map((item, index) => {
              return index === 1 ? null : (
                <PosterCard
                  className="basis-0 mx-3 mb-3 md:mb-0"
                  key={index}
                  iconName={item.iconName}
                  title={t(item.title)}
                  paragraph={t(item.paragraph)}
                  anchorHref={item.anchorHref}
                  anchorText={t(item.anchorText)}
                />
              )
            })}
          </div>
          <div className="flex flex-col lg:flex-row gap-3 items-center justify-center mt-10">
            <Link to="/sign-up" className="btn btn-bold btn-orange inline-block" style={{ width: 208 }}>
              {t('button.freeTrial.0')}
            </Link>
            <Link
              to="/lp/get-a-demo/"
              className="btn btn-bold bg-white border-white text-secondary-600 inline-block"
              style={{ width: 208 }}
            >
              {t('button.getDemo.0')}
            </Link>
          </div>
        </section>
        <section className="hidden xl:block px-10 py-24">
          <h2 className="font-black text-center text-4.5xl text-primary-600">{t('heading.productPlatform')}</h2>
          <div className="flex justify-center mt-16">
            <img
              src="https://static.mlytics.com/images/website/mlytics_platform.svg"
              alt={t('multiCdnManagement.section8.imageAlt')}
              style={{ height: 'auto', maxWidth: '1260px' }}
            />
          </div>
        </section>
        <section className="p-10">
          <h2 className="text-center text-primary-600 text-6xl">{t('heading.latestNews')}</h2>
          <div className="flex flex-col md:flex-row items-center justify-center mt-10 mb-20">
            {newsList.map((item, index) => (
              <NewsCard
                key={index}
                className="w-1/4 mx-2 mb-3 md:mb-0"
                flag={t(item.flag)}
                title={t(item.title)}
                subtitle={t(item.subtitle)}
                paragraph={t(item.paragraph)}
                anchorHref={item.anchorHref}
                imageSrc={item.imageSrc}
                imageAlt={t(item.imageAlt)}
              />
            ))}
          </div>
        </section>
      </React.Fragment>
    </Layout>
  )
}

export default MultiPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
